import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/role/list',
        method: 'get',
        params: query
    })
}

export function saveRole(data) {
    return request({
        url: '/admin/role',
        method: 'post',
        data
    })
}

export function delRole(data) {
    return request({
        url: '/admin/role/del',
        method: 'post',
        data
    })
}

export function fetchPowerList(query) {
    return request({
        url: '/admin/power/list',
        method: 'get',
        params: query
    })
}
