<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>用户操作日志</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column label="账号" prop="user.phone" align="center">
				</el-table-column>
                <el-table-column label="时间" prop="startTime" align="center">
                </el-table-column>
                <el-table-column label="操作描述" prop="description" align="center">
                </el-table-column>
                <el-table-column label="参数" prop="params" align="center">
                </el-table-column>
                <el-table-column label="IP" prop="ipAddress" align="center">
                </el-table-column>
                <el-table-column label="结果" prop="result" align="center">
                    <template slot-scope="{row}">
                        {{row.result && row.result.length > 50 ?
                        row.result.replace('apiResult(code=0, data=apiPagesData(','').substring(0, 50) + '...'
                        : row.result}}
                    </template>
                </el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				query: {
                    userId: 0,
					page: 1,
					pagesize: 10,
				},
				total: ''
			};
		},
		created() {
            if(this.$route.query.userId){
                this.query.userId = this.$route.query.userId
                this.getList();
            }
		},
		methods: {
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/log/list", this.query).then((res) => {
					if (res.data.code == 0) {
                        let list = res.data.data.list;
						this.tableData = list;
						this.total = res.data.data.total
					}
				});
			},
			handleSort(e) {
                this.query.sortProp = e.prop
                this.query.sortOrder = e.order
                this.query.page = 1
                this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
