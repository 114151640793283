var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"screen"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("用户审核")])],1),_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.formInline}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("查询")])],1),_c('el-form-item',[_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.downloadLoading),expression:"downloadLoading"}],attrs:{"type":"primary"},on:{"click":_vm.handleDownload}},[_vm._v("导出Excel")])],1)],1)],1),_c('el-table',{staticClass:"main-table",attrs:{"data":_vm.tableData,"header-row-class-name":"table-header","cell-class-name":"table-hover","row-class-name":_vm.tableRowClassName,"default-sort":{prop: 'modifyTime', order: 'descending'}},on:{"sort-change":_vm.handleSort}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","index":_vm.indexMethod,"width":"50","align":"center"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"phone","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"账号锁定","prop":"lockEndTime","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.lockEndTime)?_c('span',[_vm._v("锁定到期时间("+_vm._s(row.lockEndTime)+")")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.lock(row.id)}}},[_vm._v("锁定")]),(row.lockEndTime)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.unlock(row.id)}}},[_vm._v("解锁")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"查看详情","sortable":"custom","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.showProperties == 0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showProperties(row.id, 1)}}},[_vm._v("允许")]):_vm._e(),(row.showProperties == 1)?_c('el-button',{attrs:{"type":"warning"},on:{"click":function($event){return _vm.showProperties(row.id, 0)}}},[_vm._v("取消")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"查看全部分页","sortable":"custom","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.showAllProduct == 0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showAllProduct(row.id, 1)}}},[_vm._v("允许")]):_vm._e(),(row.showAllProduct == 1)?_c('el-button',{attrs:{"type":"warning"},on:{"click":function($event){return _vm.showAllProduct(row.id, 0)}}},[_vm._v("取消")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"审核状态","sortable":"custom","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 0)?_c('el-tag',{attrs:{"type":"warning","plain":"","disable-transitions":""}},[_vm._v("未审核")]):_vm._e(),(row.status == 1)?_c('el-tag',{attrs:{"type":"primary","plain":"","disable-transitions":""}},[_vm._v("审核通过")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"公司类型","sortable":"custom","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('el-tag',{attrs:{"type":"primary","plain":"","disable-transitions":""}},[_vm._v("保险")]):_vm._e(),(row.type == 2)?_c('el-tag',{attrs:{"type":"primary","plain":"","disable-transitions":""}},[_vm._v("经销商")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"所在地区","prop":"addressCode","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.type==1)?[_vm._v(" "+_vm._s(row.addressCode ? _vm.CodeToText(row.addressCode.split(',')) : '')+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"公司名称","prop":"companyName","align":"center"}}),_c('el-table-column',{attrs:{"label":"联系人","prop":"contact","align":"center"}}),_c('el-table-column',{attrs:{"label":"联系人电话","prop":"contactPhone","align":"center"}}),_c('el-table-column',{attrs:{"label":"工号","prop":"jobNo","align":"center"}}),_c('el-table-column',{attrs:{"label":"地址","prop":"address","align":"center"}}),_c('el-table-column',{attrs:{"prop":"addtime","label":"注册时间","sortable":"custom","align":"center"}}),_c('el-table-column',{attrs:{"prop":"logs","label":"日志","sortable":"custom","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"operation-btn",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.logShow(row)}}},[_vm._v("查看("+_vm._s(row.logs)+")")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"230","align":"center","class-name":"table-operate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 0)?_c('el-button',{staticClass:"operation-btn",attrs:{"size":"small","title":"审核通过","type":"primary"},on:{"click":function($event){return _vm.passedClick(row, 1)}}},[_vm._v("审核通过")]):_vm._e(),(row.status == 1)?_c('el-button',{staticClass:"operation-btn",attrs:{"size":"small","title":"审核通过","type":"primary"},on:{"click":function($event){return _vm.passedClick(row, 0)}}},[_vm._v("取消审核")]):_vm._e(),_c('el-button',{staticClass:"operation-btn",attrs:{"size":"small","title":"重置密码","type":"primary"},on:{"click":function($event){return _vm.resetPwd(row)}}},[_vm._v("重置密码")]),(_vm.power('sysuser'))?_c('el-button',{attrs:{"type":"danger","size":"small","title":"删除"},on:{"click":function($event){return _vm.deleteClick(row)}}},[_vm._v("删除")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":this.total,"current-page":_vm.query.page,"page-size":_vm.query.pagesize},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.getList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }