<template>
	<div class="account-details">
		<div class="wrapper">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>角色设置</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="account-con">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="main-ruleForm">
					<el-form-item label="名称:" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="权限:" prop="powerIds">
                        <el-select v-model="ruleForm.powerIds" multiple placeholder="请选择">
                            <el-option
                                v-for="item in powerList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
					</el-form-item>
					<el-form-item>
						<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
    import { fetchPowerList } from "../../api/role";
    import { saveRole } from "../../api/role";

    export default {
		components:{
		},
		data() {
			return {
                powerList: [],
				ruleForm: {
                    name: '',
                    powerIds: []
				},
                rules: {
                    powerIds: [{
                        required: true,
                        message: '请选择权限',
                        trigger: 'change'
                    }],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }],
				},
			};
		},
        created() {
            this.getPowerList()
            if(this.$route.params.role){
                this.ruleForm = JSON.parse(this.$route.params.role)
            }
        },
        methods: {
            getPowerList() {
                fetchPowerList().then(response => {
                    this.powerList = response.data
                })
            },
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
                        saveRole(this.ruleForm).then(response => {
                            this.ruleForm.id = response.id
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.$router.push({path: '/role'})
                        })
                    } else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
