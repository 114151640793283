<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>账户管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addClick">添加账号</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column label="账号" prop="account" align="center">
				</el-table-column>
                <el-table-column label="姓名" prop="name" align="center">
                </el-table-column>
				<el-table-column prop="role" label="人员类型" sortable="custom">
					<template slot-scope="{row}">
						<el-tag v-if="row.role" type="primary" plain disable-transitions>{{row.role.name}}</el-tag>
                        <el-tag v-if="!row.role" type="primary" plain disable-transitions>普通用户</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="addtime" label="添加时间" sortable align="center"></el-table-column>
				<el-table-column label="操作" width="300" align="center" class-name="table-operate">
					<template slot-scope="{row}">
						<el-button class="operation-btn" size="small" title="账号设置" type="primary"
							@click="editClick(row)">账号设置</el-button>
                        <el-button class="operation-btn" size="small" title="重置密码" type="primary"
                                   @click="resetPwd(row)">重置密码</el-button>
						<el-button type="danger" size="small" title="删除" @click="deleteClick(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
	export default {
		components: {},
		data() {
			return {
				formInline: {
					user: '',
					region: ''
				},
				tableData: [],
				query: {
                    keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
			onSubmit() {
                this.query.page = 1
                this.getList()
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/sysuser/list", this.query).then((res) => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.list;
						this.total = res.data.data.total
					}
				});
			},
            addClick() {
                this.$router.push("/accountDetails")
            },
			editClick(row) {
				this.$router.push("/accountDetails?id=" + row.id)
			},
            deleteClick(row) {
                this.$confirm("确定要永久删除该用户吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        http.fetchPost("/api/admin/sysuser/del", { id: row.id }).then((res) => {
                            if (res.data.code == 0) {
                                this.getList()
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                            }
                        });
                    })
            },
			resetPwd(row) {
				this.$confirm("确定要重置该用户密码吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
                        http.fetchPost("/api/admin/sysuser/resetpwd", { id: row.id }).then((res) => {
                            if (res.data.code == 0) {
                                this.$message({
                                    type: "success",
                                    message: "密码重置成功，新密码为：123456!",
                                });
                            }
                        });
					})
			},
			handleSort(e) {
                this.query.sortProp = e.prop
                this.query.sortOrder = e.order
                this.query.page = 1
                this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
