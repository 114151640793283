<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>角色管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addClick">添加角色</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column label="名称" prop="name" align="center">
				</el-table-column>
                <el-table-column label="权限" prop="powers" :formatter="powerFormat" align="center">
                </el-table-column>
				<el-table-column label="操作" width="300" align="center" class-name="table-operate">
					<template slot-scope="{row}">
						<el-button v-if="row.id != 1" class="operation-btn" size="small" title="修改" type="primary"
							@click="editClick(row)">修改</el-button>
						<el-button v-if="row.id != 1" type="danger" size="small" title="删除" @click="deleteClick(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
    import { fetchList, delRole } from "../../api/role";

    export default {
		components: {},
		data() {
			return {
				tableData: [],
				query: {
                    keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
			onSubmit() {
                this.query.page = 1
                this.getList()
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
			},
            addClick() {
                this.$router.push("/roleDetail")
            },
			editClick(row) {
				// this.$router.push(`/roleDetail?role=${JSON.stringify(row)}`)
                this.$router.push({
                    name: 'roleDetail',
                    params: {
                        role: JSON.stringify(row)
                    }
                })
			},
            deleteClick(row) {
                this.$confirm("确定要永久删除该角色吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        delRole({ id: row.id }).then(() => {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.getList()
                        })
                    })
            },
			handleSort(e) {
                this.query.sortProp = e.prop
                this.query.sortOrder = e.order
                this.query.page = 1
                this.getList()
			},
            powerFormat(row){
                let _powers = []
                if(row.powers){
                    row.powers.forEach(item => {
                        _powers.push(item.name)
                    })
                }
                return _powers.toString()
            }
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
