<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>用户订单</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
                <el-table-column label="标题" prop="title" align="center">
                </el-table-column>
                <el-table-column label="数量" prop="totalNum" align="center">
                </el-table-column>
                <el-table-column label="总价" prop="totalPrice" align="center">
                </el-table-column>
                <el-table-column label="收货人" prop="name" align="center">
                </el-table-column>
				<el-table-column label="手机号" prop="phone" align="center">
				</el-table-column>
                <el-table-column label="收货地址" prop="address" align="center">
                </el-table-column>
				<el-table-column prop="addTime" label="下单时间" sortable align="center"></el-table-column>
				<el-table-column label="操作" width="300" align="center" class-name="table-operate">
					<template slot-scope="{row}">
                        <el-button class="operation-btn" size="small" title="查看明细" type="primary"
                                   @click="showDetails(row)">查看明细</el-button>
						<el-button type="danger" size="small" title="删除" @click="deleteClick(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
            <el-dialog title="订单明细" :visible.sync="detailDialog" width="50%" custom-class="main-dialog">
                <el-table :data="details" class="main-table" header-row-class-name="table-header"
                          cell-class-name="table-hover" :row-class-name="tableRowClassName"
                 >
                    <el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
                    </el-table-column>
                    <el-table-column label="订货备件代码" prop="buyCode" align="center">
                    </el-table-column>
                    <el-table-column label="备件名称" prop="name" align="center">
                    </el-table-column>
                    <el-table-column label="价格" prop="price" align="center">
                    </el-table-column>
                    <el-table-column label="数量" prop="num" align="center">
                    </el-table-column>
                    <el-table-column label="总价" prop="totalPrice" align="center">
                        <template slot-scope="scope">
                            <span>{{(scope.row.price * scope.row.num).toFixed(2)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
		</div>
	</div>
</template>

<script>
    import { fetchList, deleteOrder } from "../../api/order";

    export default {
		components: {},
		data() {
			return {
                detailDialog: false,
                details: [],
				formInline: {
					user: '',
					region: ''
				},
				tableData: [],
				query: {
                    keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
            showDetails(row) {
                this.details = row.orderDetails
                this.detailDialog = true;
            },
			onSubmit() {
                this.query.page = 1
                this.getList()
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
			},
            deleteClick(row) {
                this.$confirm("确定要永久删除该用户吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        deleteOrder({ id: row.id }).then(() => {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.getList();
                        });
                    })
            },
			handleSort(e) {
                this.query.sortProp = e.prop
                this.query.sortOrder = e.order
                this.query.page = 1
                this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
